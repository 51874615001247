<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div class="d-flex align-items-start justify-content-between">
      <h1 class="mt-0">Cookies Policy</h1>
      <button v-if="back" class="btn btn-primary font-weight-bold" @click="$emit('back')">{{ $t("Close") }}</button>
    </div>
    <div v-html="convertMarkdownToHtml(content)"></div>
  </div>
</template>

<script>
import MarkdownIt from "markdown-it";
export default {
  name: "CookiesPolicy",
  props: {
    back: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      md: new MarkdownIt(),
      content: ""
    }
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler() {
        this.fetchPageContent();
      }
    }
  },
  methods: {
    convertMarkdownToHtml(markdownText) {
      return this.md.render(markdownText);
    },
    async fetchPageContent() {
      try {
        const language = this.$i18n.locale;
        const response = await fetch(`/documents/cookie_policy_${language}.md`);
        this.content = await response.text();
      } catch (error) {
        console.error(error);
        this.content = this.$i18n.t("Error retrieving general conditions content, try again later or contact the support.");
      }
    }
  },
};
</script>

<style>
h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

h2 {
  font-size: 22px;
  margin-bottom: 15px;
}

p {
  margin-bottom: 15px;
}

ul {
  margin-bottom: 15px;
  margin-left: 25px;
}
</style>